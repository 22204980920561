.h3-uppercase {
	@apply my-2 text-lg font-bold uppercase;
}

.label {
	@apply font-semibold color-primary mr-1;
}

.edit-section-container {
	@apply flex content-start items-center text-base;
}
