@import '../grid/variables';

.light-theme .blue_theme {
	@import './buttons';
	@import './drawer';
	@import './form';
	@import './checkbox';
	@import './radio';

	.topbar {
		background-color: $toolbar;
	}
}

.light-theme {
	@import './card';
	@import './typography';
	@import './form';
}

@import './borderColor';
@import './table';
@import './list';
@import './card';

@media (max-width: map-get($breakpoints, medium)) {
	.mat-mdc-form-field-hint-wrapper {
		padding: 0px !important;
	}
}
