@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700;800&display=swap');

// font
$font-family: 'Plus Jakarta Sans', sans-serif;

// light color variable
$sidebarbg: #f7f7f8;
$background: white;
$cardbg: white;
$toolbar: white;
$cardshadow: rgb(145 158 171 / 30%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;
$hoverBgColor: #f6f9fc;

// Sidenav
$sidenav-desktop: 270px !default;
$sidenav-mini: 80px !default;
$header-height: 70px !default;

//BorderColor
$borderColor: #e5eaef;
$borderformColor: #dfe5ef;

// custom
$primary: #044389;
$accent: #5995ed;
$warning: #ffad05;
$error: #c03221;
$success: #77ba99;
$success-dark: #1a8b77;
$white: #ffffff;

$light: #f2f6fa;

$gray-100: #7c8fac;

$light-primary: #ecf2ff;
$light-accent: #e8f7ff;
$light-warning: #fef5e5;
$light-error: #fdede8;
$light-success: #e6fffa;

// layout
$boxedWidth: 1280px;

$border-radius: 7px;

// text color
$textPrimary: #2a3547;
