@import '../grid/variables';

@media (max-width: map-get($breakpoints, medium)) {
	code-input {
		--item-spacing: 0px !important;
		--item-height: 3em !important;
		--item-border: none !important;
		--item-border-bottom: 2px solid #dddddd !important;
		--item-border-has-value: none !important;
		--item-border-bottom-has-value: 2px solid #888888 !important;
		--item-border-focused: none !important;
		--item-border-bottom-focused: 2px solid #809070 !important;
		--item-shadow-focused: none !important;
		--item-border-radius: 0px !important;
	}
}
