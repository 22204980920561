[dir='rtl'] {
	// ------------------------------------------------------
	// header
	// ------------------------------------------------------
	.upgrade-bg {
		left: 0;
		right: unset;
		transform: scaleX(-1);
	}

	// customizer
	.customizerBtn {
		left: 30px;
		right: unset;
	}

	//   logo flip
	.branding img {
		transform: scaleX(-1);
	}

	.breadcrumb-icon {
		left: 19px;
		right: unset;
	}

	.breadcrumb-item {
		&:first-child {
			margin-left: 0;
			margin-right: -20px;
		}
		&.active {
			margin-left: 0;
			margin-right: 20px;
		}
	}

	// sidebar
	.sidebar-list.mdc-list .menu-list-item .mdc-list-item__start {
		margin-right: 0 !important;
		margin-left: 14px !important;
	}

	// minisidebar
	&.sidebarNav-mini {
		.contentWrapper {
			margin-right: $sidenav-mini !important;
			margin-left: 0 !important;
			transition: swift-ease-out(width);
		}
		.sidebarNav {
			// sidebar
			.sidebar-list {
				.menu-list-item {
					.mdc-list-item__start {
						margin-right: 8px !important;
						margin-left: 7px !important;
					}
				}
			}
			&:hover {
				.sidebar-list {
					.menu-list-item {
						.mdc-list-item__start {
							margin-right: 0 !important;
							margin-left: 16px !important;
						}
					}
				}
			}
		}
	}
}
