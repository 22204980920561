@use 'sass:map';
@use 'sass:math';
@use 'variables';

@function bp($name, $breakpoints: variables.$breakpoints) {
	$min: map.get($breakpoints, $name);

	@return $min;
}

// Media of at least the minimum breakpoint width.
@mixin bp-gt($name, $breakpoints: variables.$breakpoints) {
	$min: bp($name, $breakpoints);

	@if $min {
		@media (min-width: $min) {
			@content;
		}
	} @else {
		@content;
	}
}

// Media of at most the maximum breakpoint width.
@mixin bp-lt($name, $breakpoints: variables.$breakpoints) {
	$max: bp($name, $breakpoints) - 1px;

	@if $max {
		@media (max-width: $max) {
			@content;
		}
	} @else {
		@content;
	}
}

@mixin make-grid-columns($i: 1, $list: '.col') {
	@each $breakpoint, $infix in variables.$breakpoint-infixs {
		$infix: if($infix == '', '', '-#{$infix}');

		@if ($infix != '') {
			$list: '#{$list}, .col#{$infix}';
		}

		@for $i from 1 through variables.$grid-columns {
			$list: '#{$list}, .col#{$infix}-#{$i}';
		}
	}

	#{$list} {
		position: relative;
		width: 100%;
		padding-right: variables.$grid-gutter * 0.5;
		padding-left: variables.$grid-gutter * 0.5;
	}
}

@mixin loop-grid-columns($columns: $grid-columns, $breakpoint-infix: '') {
	$infix: if($breakpoint-infix == '', '', '-#{$breakpoint-infix}');

	.col#{$infix} {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
	}

	@for $i from 1 through $columns {
		.col#{$infix}-#{$i} {
			flex: 0 0 math.percentage(math.div($i, $columns));
			max-width: math.percentage(math.div($i, $columns));
		}

		.offset#{$infix}-#{$i} {
			margin-left: math.percentage(math.div($i, $columns));
		}
	}
}
