@use 'sass:map';
@use 'sass:meta';
@use 'sass:list';
@use 'variables';

@use 'functions';
@use 'flexbox';
@use 'display';
@use 'border';
@use 'text';
@use 'spacing';
@use 'rounded';
@use 'sizing';

$utilities: functions.map-collect(
	variables.$utilities,
	display.$utilities,
	flexbox.$utilities,
	spacing.$utilities,
	border.$utilities,
	rounded.$utilities,
	text.$utilities,
	sizing.$utilities
);

@each $key, $utility in $utilities {
	$values: map.get($utility, values);

	// If the values are a list or string, convert it into a map
	@if meta.type-of($values) == 'string' or meta.type-of(list.nth($values, 1)) != 'list' {
		$values: list.zip($values, $values);
	}

	$properties: map.get($utility, property);
	$property-class-prefix: map.get($utility, class);

	// Utility class maybe empty, (e.g. with position class)
	$property-class-prefix-hyphen: if($property-class-prefix == '', $property-class-prefix, $property-class-prefix + '-');

	@each $class-modifier, $value in $values {
		.#{$property-class-prefix-hyphen + $class-modifier} {
			@each $property in $properties {
				#{$property}: $value !important;
			}
		}
	}
}

$ltrutilities: functions.map-collect(spacing.$ltr);
@each $key, $utility in $ltrutilities {
	$values: map.get($utility, values);

	// If the values are a list or string, convert it into a map
	@if meta.type-of($values) == 'string' or meta.type-of(list.nth($values, 1)) != 'list' {
		$values: list.zip($values, $values);
	}

	$properties: map.get($utility, property);
	$property-class-prefix: map.get($utility, class);

	// Utility class maybe empty, (e.g. with position class)
	$property-class-prefix-hyphen: if($property-class-prefix == '', $property-class-prefix, $property-class-prefix + '-');

	@each $class-modifier, $value in $values {
		[dir='ltr'] .#{$property-class-prefix-hyphen + $class-modifier} {
			@each $property in $properties {
				#{$property}: $value !important;
			}
		}
	}
}

$rtlutilities: functions.map-collect(spacing.$rtl);
@each $key, $utility in $rtlutilities {
	$values: map.get($utility, values);

	// If the values are a list or string, convert it into a map
	@if meta.type-of($values) == 'string' or meta.type-of(list.nth($values, 1)) != 'list' {
		$values: list.zip($values, $values);
	}

	$properties: map.get($utility, property);
	$property-class-prefix: map.get($utility, class);

	// Utility class maybe empty, (e.g. with position class)
	$property-class-prefix-hyphen: if($property-class-prefix == '', $property-class-prefix, $property-class-prefix + '-');

	@each $class-modifier, $value in $values {
		[dir='rtl'] .#{$property-class-prefix-hyphen + $class-modifier} {
			@each $property in $properties {
				#{$property}: $value !important;
			}
		}
	}
}
