@import 'variables';
@import './grid/variables';

.required::after {
	content: ' *';
}

.color-warning {
	color: $error;
}

.color-success {
	color: $success;
}

.color-primary {
	color: $primary;
}

.in-input-btn {
	min-height: 56px;
}

.max-width-form {
	max-width: 450px;
}

.ellipsis-text {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.address-select-invalid .mat-mdc-select-placeholder {
	color: #c03221 !important;
}

.readonly {
	cursor: not-allowed;
	pointer-events: none;
}

.edit-icon-primary {
	color: $primary;
	:hover {
		color: rgba($color: $primary, $alpha: 0.7);
		cursor: pointer;
	}
}

.btn-icon {
	width: 1.5em !important;
	height: 1.5em !important;
	vertical-align: bottom;
	margin-right: 0.5rem;
	margin-left: -0.25rem;
}

mat-checkbox {
	&.f-s-14 .mdc-label {
		font-size: 14px;
	}

	&.text-justify .mdc-label {
		text-align: justify;
	}

	&.invalid .mdc-checkbox__background {
		border-color: red !important;
	}
}

.mat-mdc-snack-bar-container {
	&.error-snackbar {
		--mdc-snackbar-container-color: #c03221;
		--mat-mdc-snack-bar-button-color: #c03221;
	}
}

.mat-mdc-snack-bar-container {
	&.success-snackbar {
		--mdc-snackbar-container-color: #044389;
		--mat-mdc-snack-bar-button-color: #044389;
	}
}

.light-hint {
	color: $gray-100;
	font-size: 12px;
}

@media (max-width: $small) {
	.flex-wrap-sm {
		flex-wrap: wrap;
	}
}

.p-absolute {
	position: absolute;
}

.list-style-type-none {
	list-style-type: none;
}

// Disable stepper header navigation
.mat-step-header {
	pointer-events: none !important;
}

.nv-status-block {
	border-top: 1px solid #7c8fac;
}

.center-fixed {
	top: 50%;
	left: 50%;
	position: fixed !important;
	z-index: 1;
}

.doc-wrapper {
	@apply inline-block relative;
	img {
		border-radius: 5px;
		width: 100%;
	}
}

.remove-image-btn {
	position: absolute;
	top: 0;
	right: 0;
	max-width: 20px;
	color: #c03221;
	background-color: white;
	border: 1px solid;
	border-radius: 2px;
	cursor: pointer;
}
