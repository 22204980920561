.container {
	max-width: 1200px;
	padding-left: 24px;
	padding-right: 24px;
	margin: 0 auto;
	&.full-width {
		display: flex;
		align-items: center;
		width: 100%;
	}
}

.demos-dd {
	max-width: 1200px !important;
}

.img-overlay {
	overflow: auto;
	position: relative;
	.overlay-content {
		display: none;
	}
	&:hover {
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 8;
			background-color: rgba(55, 114, 255, 0.2);
		}
		.overlay-content {
			display: block;
			transform: translate(-50%, -50%);
			position: absolute;
			left: 50%;
			right: 50%;
			top: 50%;
			min-width: 115px;
			z-index: 9;
		}
	}
}

.topbar-xl {
	height: 80px;
	z-index: 1;
}

// ----------------------------------------------------------
// banner
// ----------------------------------------------------------
@media (min-width: 900px) {
	.banner-title {
		font-size: 54px !important;
		line-height: 60px !important;
	}
}

@media (max-width: 1200px) {
	.banner-section {
		padding-top: 60px;
	}
}

.banner-title {
	font-size: 36px;
	line-height: 44px;
}

.banner-subtitle {
	line-height: 25px;
}

.btn-custom {
	height: 56px !important;
	padding: 0 52px !important;
	font-size: 16px;
	&.sm {
		padding: 0 34px !important;
	}
}

@media (max-width: 767px) {
	.btn-custom {
		padding: 0 36px !important;
	}
}

.banner-slider {
	background-color: $light-primary;
	min-width: 2000px;
	height: calc(100vh - 100px);
	max-height: 790px;
	border-radius: $border-radius;
	overflow: hidden;
}

.slider-img {
	animation: slideup 35s linear infinite;
}

.slider-img2 {
	animation: slidedown 35s linear infinite;
}

@keyframes slideup {
	0% {
		transform: translate3d(0, 0, 0);
	}
	100% {
		transform: translate3d(0px, -100%, 0px);
	}
}

@keyframes slidedown {
	0% {
		transform: translate3d(0, -100%, 0);
	}
	100% {
		transform: translate3d(0px, 0, 0px);
	}
}

// sections

.spacer {
	padding: 80px 0 100px;
}

.section-title {
	font-size: 28px;
	line-height: 32px;
}

@media (min-width: 991px) {
	.section-title {
		font-size: 36px;
		line-height: 43px;
	}
}

.bg-extra-light {
	background-color: $hoverBgColor;
}

// demo slider
.demo-slider {
	margin-top: 40px;
	.demo-slide {
		animation: slide 45s linear infinite;
	}
}

@keyframes slide {
	0% {
		transform: translate3d(0, 0, 0);
	}
	100% {
		transform: translate3d(-100%, 0, 0);
	}
}

.lh-base {
	line-height: 1.75;
}

// call to action

.shape-card {
	// background-image: url(./images/landingpage/line-bg.svg);
	background-repeat: no-repeat;
	background-position: center center;
	overflow: hidden;
}

.spacer-sm {
	padding-top: 60px;
	padding-bottom: 30px;
}

.spacer-bottom {
	padding-bottom: 60px;
}

.section-title2 {
	font-size: 30px;
	line-height: 36px;
	font-weight: 700;
}

.c2a-card {
	margin-top: -70px;
	background-repeat: no-repeat;
	background-position: center;
}

.border-white {
	border: 1px solid $white !important;
}

.border-accent {
	border: 1px solid $accent !important;
}

.border-primary {
	border: 1px solid $primary !important;
}

.hstack {
	display: flex;
	flex-direction: row;
	gap: 20px;
}

@media (max-width: 767px) {
	.hstack {
		flex-direction: column;
	}
	.bg-size-cover {
		background-size: cover;
	}
}
