@import '../../assets/scss/grid/variables';

*,
:after,
:before {
	box-sizing: border-box;
}

body {
	font-family: $font-family;
	color: $textPrimary;
	font-size: 14px;
	line-height: 1.334rem;
}

html .mat-drawer-container {
	background-color: #f7f7f8;
}

.mainWrapper {
	display: flex;
	min-height: 100vh;
	width: 100%;
	flex: 1;
	height: 100%;
}

.pageWrapper {
	padding: 24px;
	min-height: calc(100vh - 70px);

	margin: 0 auto;

	&.maxWidth {
		max-width: $boxedWidth;
	}
}

@media (max-width: map-get($breakpoints, medium)) {
	.pageWrapper {
		margin-top: $header-height;
		margin-bottom: 48px; // livechat widget height
		padding: 4px;
	}

	.mat-mdc-card-content {
		padding: 8px !important;
	}
}

.w-100 {
	width: 100%;
}

.h-100 {
	height: 100%;
}

.h-300 {
	height: 300px;
}

.shadow-none {
	box-shadow: none !important;
}

.rounded {
	border-radius: $border-radius !important;
}

.rounded-circle {
	border-radius: 50% !important;
}

.rounded-pill {
	border-radius: 25px !important;
}

.overflow-hidden {
	overflow: hidden !important;
}

.overflow-auto {
	overflow: auto;
}

.text-decoration-none {
	text-decoration: none;
}

.position-relative {
	position: relative;
}

.table-responsive {
	overflow-x: scroll;

	td,
	mat-cell {
		white-space: nowrap;
		padding: 16px;
	}
}

.op-5 {
	opacity: 0.5;
}

.cursor-pointer {
	cursor: pointer;
}

.avatar-group {
	img {
		border: 2px solid white;
		margin-right: -5px;

		&:last-child {
			margin-right: 0;
		}
	}
}

.text-hover-primary:hover {
	.hover-text {
		color: $primary;
	}
}

.filter-sidebar {
	width: 290px;
}
