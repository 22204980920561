/* You can employee-credentials-dialog global styles to this file, and also import other style files */
html,
body {
	min-height: 100%;
}
body {
	margin: 0;
	font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.no-app-loader {
	margin: 50px auto 0;
	border: 8px solid #f3f3f3;
	border-top: 8px solid #044389;
	border-radius: 50%;
	width: 100px;
	height: 100px;
	animation: spin 2s linear infinite;
	position: absolute;
	top: 40%;
	left: 0;
	right: 0;
}

#chat-widget-container {
	z-index: 10 !important;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
