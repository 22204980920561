@use '@angular/material' as mat;

@tailwind base;
@tailwind components;
@tailwind utilities;

@include mat.core();

@import 'variables';
@import 'layouts/transitions';
@import 'helpers/color';
@import 'helpers/icon-size';

//Theme colors

@import 'themecolors/default_theme';
@include mat.all-component-themes($bluetheme);

//container layout
@import 'overrides/materialoverrides';
@import 'overrides/codeInput';
@import 'container';
@import 'layouts/layouts';
@import 'grid/grid';
@import 'helpers/custom-flex';
@import 'helpers/index';
@import 'global';

// pages
@import 'pages/dashboards';
@import 'pages/auth';
@import 'pages/landingpage';
@import 'pages/enrollments';
@import 'pages/audit-logs';

// RTL Theme
@import 'rtl/rtl';

.chip {
	@apply px-2 py-1 font-semibold rounded-xl text-xs bg-slate-100;
}
