@use 'sass:map';
@use 'variables';

$utilities: (
	'font-size': (
		property: font-size,
		class: f-s,
		values: variables.$font-sizes
	),
	'font-weight': (
		property: font-weight,
		class: f-w,
		values: variables.$font-wieghts
	),
	'font-style': (
		property: font-style,
		class: font,
		values: italic normal
	),
	'text-align': (
		property: text-align,
		class: text,
		values: center right left justify
	),
	'text-decoration': (
		property: text-decoration,
		class: text,
		values: none underline line-through
	),
	'text-transform': (
		property: text-transform,
		class: text,
		values: capitalize uppercase lowercase
	),
	'white-space': (
		property: white-space,
		class: text,
		values: (
			wrap: normal,
			nowrap: nowrap
		)
	),
	'word-wrap': (
		property: word-wrap word-break,
		class: text,
		values: (
			break: break-word
		)
	),
	'text-overflow': (
		property: text-overflow,
		class: text,
		values: ellipsis
	),
	'text-color': (
		property: color,
		class: text,
		values: (
			reset: inherit,
			current: currentColor
		)
	)
);

.lh-lg {
	line-height: 36px !important;
}

.lh-sm {
	line-height: 20px !important;
}
